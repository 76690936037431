import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  // {
  //   pid: 1,
  //   risk: 5,
  //   lpSymbol: 'ECG-USDT LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xd1b59d11316e87c3a0a069e80f590ba35cd8d8d3',
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  //   token1: 'ECG',
  //   token2: 'USDT',
  // },
  // {
  //   pid: 2,
  //   risk: 3,
  //   lpSymbol: 'ECG-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: 'ECG',
  //   token2: 'BUSD',
  // },
  // {
  //   pid: 5,
  //   risk: 3,
  //   lpSymbol: 'BNB-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: 'BNB',
  //   token2: 'BUSD',
  // },
  // {
  //   pid: 6,
  //   risk: 3,
  //   lpSymbol: 'BNB-USDT LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: 'BNB',
  //   token2: 'USDT',
  // },

  {
    pid: 0,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'ECG',
    lpAddresses: {
      97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
      56: '0x40436182063eb9f901B7721a89E196947206d2bD', // AUTO-BNB LP
    },
    tokenSymbol: 'ECG',
    tokenAddresses: {
      97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
      56: '0xf002d64DF02f1710EC99bF7084a55A80D4B1B1d8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    token1: 'ECG',
  },
  {
    pid: 1,
    risk: 3,
    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '0xA0C2d558e86ceDA2361a9cE49cF78e83Fa6E8Ef3',
      56: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f', // AUTO-BNB LP
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    isTokenOnly:true,
    token1: 'BUSD',
  },
  {
    pid: 2,
    risk: 3,
    lpSymbol: 'TUSD',
    lpAddresses: {
      97: '0xA0C2d558e86ceDA2361a9cE49cF78e83Fa6E8Ef3',
      56: '0x2E28b9B74D6d99D4697e913b82B41ef1CAC51c6C', // AUTO-BNB LP
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly:true,
    token1: 'TUSD',
  },
  {
    pid: 3,
    risk: 3,
    lpSymbol: 'DAI',
    lpAddresses: {
      97: '0xA0C2d558e86ceDA2361a9cE49cF78e83Fa6E8Ef3',
      56: '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489', // AUTO-BNB LP
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
      56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly:true,
    token1: 'DAI',
  },
  {
    pid: 4,
    risk: 3,
    lpSymbol: 'ECG-BNB LP',
    lpAddresses: {
      97: '0xA0C2d558e86ceDA2361a9cE49cF78e83Fa6E8Ef3',
      56: '0x40436182063eb9f901B7721a89E196947206d2bD', // AUTO-BNB LP
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
      56: '0x40436182063eb9f901B7721a89E196947206d2bD',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    // isTokenOnly:true,
    token1: 'ECG',
    token2:'BNB'
  },
  {
    pid: 5,
    risk: 3,
    lpSymbol: 'ECG-USDT LP',
    lpAddresses: {
      97: '0xA0C2d558e86ceDA2361a9cE49cF78e83Fa6E8Ef3',
      56: '0xD3BC6C73035B8cD9d7B84B96284b62E7F5d9491F', // AUTO-BNB LP
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
      56: '0xD3BC6C73035B8cD9d7B84B96284b62E7F5d9491F',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    // isTokenOnly:true,
    token1: 'ECG',
    token2:'USDT'
  },
  {
    pid: 6,
    risk: 3,
    lpSymbol: 'ECG-BUSD LP',
    lpAddresses: {
      97: '0xA0C2d558e86ceDA2361a9cE49cF78e83Fa6E8Ef3',
      56: '0x77730e2e072E4Ef29B7d546EA74180930438761a', // AUTO-BNB LP
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
      56: '0x77730e2e072E4Ef29B7d546EA74180930438761a',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    // isTokenOnly:true,
    token1: 'ECG',
    token2:'BUSD'
  },
  {
    pid: 7,
    risk: 3,
    lpSymbol: 'ECG-DAI LP',
    lpAddresses: {
      97: '0xA0C2d558e86ceDA2361a9cE49cF78e83Fa6E8Ef3',
      56: '0x1c871d2A122bF896E07e10D7F81BB610bDf87235', // AUTO-BNB LP
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
      56: '0x1c871d2A122bF896E07e10D7F81BB610bDf87235',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.dai,
    // isTokenOnly:true,
    token1: 'ECG',
    token2:'DAI'
  },
  {
    pid: 8,
    risk: 3,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '0xA0C2d558e86ceDA2361a9cE49cF78e83Fa6E8Ef3',
      56: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f', // AUTO-BNB LP
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
      56: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    // isTokenOnly:true,
    token1: 'BNB',
    token2:'BUSD'
  },
  {
    pid: 9,
    risk: 3,
    lpSymbol: 'BNB-USDT LP',
    lpAddresses: {
      97: '0xA0C2d558e86ceDA2361a9cE49cF78e83Fa6E8Ef3',
      56: '0x20bCC3b8a0091dDac2d0BC30F68E6CBb97de59Cd', // AUTO-BNB LP
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
      56: '0x20bCC3b8a0091dDac2d0BC30F68E6CBb97de59Cd',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    // isTokenOnly:true,
    token1: 'BNB',
    token2:'USDT'
  },
  // {
  //   pid: 7,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'BUSD',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  //   token1: 'BUSD',
  // },
  // {
  //   pid: 8,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'TUSD',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x55d398326f99059fF775485246999027B3197955', // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x55d398326f99059fF775485246999027B3197955',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  //   token1: 'TUSD',
  // },
  // {
  //   pid: 9,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'DAI',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  //   token1: 'DAI',
  // },
  // {
  //   pid: 10,
  //   risk: 3,
  //   // isTokenOnly: true,
  //   lpSymbol: 'ECG-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x3744ed0834496Aca290799151e7aC8c1aCa085Af', // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  //   token1: 'ECG',
  //   token2: 'BNB',
  // },
  // {
  //   pid: 11,
  //   risk: 3,
  //   // isTokenOnly: true,
  //   lpSymbol: 'ECG-USDT LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xCcb02aA6A91B419D9ebaE350C3f043EF1962ccBe', // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  //   token1: 'ECG',
  //   token2: 'USDT',
  // },
  // {
  //   pid: 12,
  //   risk: 3,
  //   // isTokenOnly: true,
  //   lpSymbol: 'ECG-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xC78AAa1fF8977fE7105630Eb249f894Dd086fB34', // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: 'ECG',
  //   token2: 'BUSD',
  // },
  // {
  //   pid: 13,
  //   risk: 3,
  //   // isTokenOnly: true,
  //   lpSymbol: 'ECG-DAI LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xD5C6Ec60299a9D71205dBeD69367F7D6cA814A0d', // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  //   token1: 'ECG',
  //   token2: 'DAI',
  // },
]

export default farms
