export default {
  cake: {
    56: '0xf002d64DF02f1710EC99bF7084a55A80D4B1B1d8',
    97: '0xFd5E9D36Abf74fE59803A251618C7F05aC559642',
  },
  masterChef: {
    56: '0xCef6399a796e894E97930BfC1c6fb9A553162abe',
    97: '0x19E5FbebEd9ebEaD2e6eF1fbB26718BC825E127f',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },
  usdt:{
    56:"0x55d398326f99059fF775485246999027B3197955",
    97:""
  },
  dai:{
    56:"0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    97:"0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0xd8a888adf59c21bd0943a4a574d4449edbb0281e',
  },
  refferal: {
    56: '0xA2727E08d05786524C922B28041Cc2954c27bafe',
    97: '0xa763f13b940310a17D57672BbaA0D7d5640368A1',
  },
}
