import React from 'react'
import styled from 'styled-components'
import { Heading, Text, BaseLayout, Image, Button } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import Page from 'components/layout/Page'
import FarmStakingCard from './components/FarmStakingCard'
import LotteryCard from './components/LotteryCard'
import CakeStats from './components/CakeStats'
import TotalValueLockedCard from './components/TotalValueLockedCard'
import TwitterCard from './components/TwitterCard'
import RefferalLink from './components/RefferalLink'
import RefferalCard from './components/Refferals'
import StartFarming from './components/StartFarming'
import StartInviting from './components/StartInviting'
import AddLiquik from './components/AddLiquik'
import Bottom from './components/Bottom'
import Foot from './components/Foot'
import RefferalCard3 from './components/RefferalCardno3'
import RefferalCard2 from './components/RefferalCardno2'
import RefferalCard1 from './components/RefferalCardno1'

const Hero = styled.div`
  align-items: center;
  // background-image: url('/images/egg/3.png');
  // background-repeat: no-repeat;
  // background-position: top center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-bottom: 32px;
  padding-top: 1rem;
  // text-align: center;

  // ${({ theme }) => theme.mediaQueries.lg} {
  //   // background-image: url('/images/egg/3.png'), url('/images/egg/3b.png');
  //   // background-position: left center, right center;
  //   height: 165px;
  //   padding-top: 0;
  // }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 2rem;
    flex-direction: row;
  }
`

const Cards = styled(BaseLayout)`
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 48px;

  & > div {
    grid-column: span 6;
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & > div {
      grid-column: span 8;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 6;
    }
  }
`
const Div = styled.div`
  display: flex;
  margin-bottom: 2rem;
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
const Middle = styled.div`
  margin: 2rem 0 2rem 1rem;
  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0 1rem 0 1rem;
  }
`

const Refferal: React.FC = () => {
  const TranslateString = useI18n()

  return (
    <Page>
      <Hero>
        {/* <Heading as="h1" size="xl" mb="24px" color="secondary">
          {TranslateString(576, 'Goose Finance')}
        </Heading>
        <Text>{TranslateString(578, 'Top 3 best DEFI app on Binance Smart Chain.')}</Text> */}
        {/* <img src="/images/logowithtext.png" alt="E logo" /> */}
        {/* <div>
          <p style={{ color: 'white', fontSize: '2.5rem' }}>
            The First DEX on BSC & Matic network with a three-type referral system
          </p>
          <Text fontSize="1.5rem" style={{ marginTop: '2rem', color: '#0F52BA' }}>
            Enjoy profitable yield farming and exchanges with the lowest fees in DeFi space
          </Text>
        </div>
        <Image src="images/robot.png" alt="robot" width={177} height={280} /> */}
      </Hero>
      {/* <Div>
        <StartFarming />
        <StartInviting />
        <AddLiquik />
      </Div> */}
      {/* <div>
        <Cards>
          <FarmStakingCard />
          <TwitterCard />
          <CakeStats />
          <TotalValueLockedCard />
        </Cards>
      </div> */}
      <Div>
        <div>
          <p style={{ color: 'white', fontSize: '2.5rem' }}>Invite your friends.</p>
          <p style={{ color: 'white', fontSize: '2.5rem' }}>Earn cryptocurrency together</p>
          <br />
          <Text>Earn a certain commission reward from your
            friends swaps on echangy and 5% from their
            earnings on Farms & Launchpools.</Text>
          {/* <Text fontSize="1.5rem" style={{marginTop:"2rem",color:"#0F52BA"}}>Earn a certain commission reward from friends swaps on Echangy and 5% from their earnings on Farms & Launchpools</Text> */}
          {/* <Button style={{ background: 'blue', marginTop: '2rem' }}>Invite Friends</Button> */}
        </div>
        <Image src="/images/image.png" alt="hf" width={367} height={327} />
      </Div>
      <Cards>
        <RefferalCard />
        <RefferalLink />
      </Cards>
      <Row>
        <Heading>How to invite friends</Heading>
      </Row>
      <Div style={{ marginTop: '2rem' }}>
        {/* <img src="/images/link.png" alt="jf" style={{marginRight:"auto"}} /> */}
        <RefferalCard1 />
        {/* <Image src="/images/invite.png" alt="jf" width={367} height={267} /> */}
        {/* <Middle>
          <img src="/images/invite.png" alt="v" style={{height:"20rem"}} />
        </Middle> */}
        <RefferalCard2 />
        <RefferalCard3 />
        {/* <img src="/images/quick.png" alt="jf"  style={{marginLeft:"auto"}} /> */}
      </Div>
      {/* <Bottom /> */}
      {/* <Foot /> */}
    </Page>
  )
}

export default Refferal
